import classNames from "classnames";
import React, {useEffect, useState} from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import BsForm from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import {Field, Form} from "react-final-form";
import TextField from "../Shared/Form/TextField";
import {formatPhoneNumber, parsePhoneNumber} from "../Shared/Form/formatters";
import {
  composeValidators,
  required,
  validateEmail,
  validateMobilePhone,
  validatePostalCode,
} from "../Shared/Form/validators";
import styles from "./VehicleReservationOnlyForm.module.scss";
import {
  createWebReservation,
  sendDeepLink,
} from "../../lib/reservation-service";
import {FORM_ERROR} from "final-form";
import useSWRImmutable from "swr/immutable";
import {listProgramOfferings} from "../../lib/reservation-service";
import Link from "next/link";
import useIsMounted from "../hooks/useIsMounted";

export function VehicleReservationOnlyForm({
  disableVehicleSelection = false,
  selectedProgram,
  selectedPricingOptionIndex,
}) {
  const [showConfirmScreen, setShowConfirmScreen] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const isMounted = useIsMounted();
  const [sentPhoneNumber, setSentPhoneNumber] = useState("");
  const {data} = useSWRImmutable("listProgramOfferings", () =>
    listProgramOfferings()
  );
  const programsList =
    data?.programsList?.filter(({name}) => {
      switch (name) {
        case "TeslaModel3LongRange":
        case "TeslaModelY":
          return false;
        default:
          return true;
      }
    }) || [];

  const showProgramDisclosure = (programId) => {
    if (!programId) {
      return false;
    }

    const program = programsList.find(({id}) => id === programId);

    if (program.name === "TeslaModel3") {
      return false;
    }

    return true;
  };

  function onSuccessfulSubmit(values) {
    setSentPhoneNumber(values.mobileNumber);
    setShowConfirmScreen(true);
  }

  function handleOnClickDownloadApp() {
    sendDeepLink(sentPhoneNumber);

    setShowSuccessMessage(true);

    setTimeout(() => {
      if (isMounted.current) {
        setShowSuccessMessage(false);
      }
    }, 3000);
  }

  function onSubmit(values) {
    const pricingOptionsList = programsList
      .find(({id}) => id === values.programId)
      .pricingOptionsList?.[0]?.pricingOptionsList?.slice(0)
      .sort(({monthlyRent: A}, {monthlyRent: B}) => A - B);

    const lowestPricingOption = pricingOptionsList?.[0];
    const selectedPricingOption =
      typeof selectedPricingOptionIndex === "number"
        ? pricingOptionsList[selectedPricingOptionIndex]
        : lowestPricingOption;
    const {startFee, monthlyRent, priceTermMonths} =
      selectedPricingOption || {};

    return createWebReservation({
      ...values,
      startFee,
      monthlyRent,
      priceTermMonths,
    })
      .then(() => {
        onSuccessfulSubmit(values);
      })
      .catch((error) => ({
        [FORM_ERROR]: `Error: ${error?.message}`,
      }));
  }

  if (showConfirmScreen) {
    return (
      <div className={styles.confirmScreenContainer}>
        <div className={styles.heading}>Reservation Confirmed</div>
        <div className={styles.subheading}>
          Thank you for submitting your information. You are one step closer to
          your next EV.
        </div>
        <hr />
        <div className={styles.secondaryHeading}>Check your texts</div>
        <div className={styles.secondarySubheading}>
          {`We've sent you a link to download the app.`}
        </div>
        <div className={styles.resendMsg}>
          {`Didn't receive it? Tap below to resend it.`}
        </div>
        <Button
          className={classNames("w-100", styles.downloadButton)}
          onClick={handleOnClickDownloadApp}
          type="button"
          variant="primary"
        >
          Download the app
        </Button>
        {showSuccessMessage && (
          <div
            className={styles.successMessage}
          >{`We've sent your app download link!`}</div>
        )}
      </div>
    );
  }

  if (!data) {
    return null;
  }

  return (
    <div className={styles.formContainer}>
      <div className={styles.heading}>Start your reservation</div>
      <div className={styles.subheading}>
        Reserve your spot to lock in your savings. No obligations. No
        commitments.
      </div>
      <Form
        initialValues={{
          firstName: "",
          lastName: "",
          mobileNumber: "",
          email: "",
          programId: selectedProgram?.id,
          zip: "",
        }}
        onSubmit={onSubmit}
        render={({handleSubmit, submitting, submitError, values}) => (
          <form onSubmit={handleSubmit}>
            <Row className={styles.formRow}>
              {!disableVehicleSelection && (
                <Col xs={12}>
                  <Field name="programId" validate={required}>
                    {({meta, input}) => (
                      <BsForm.Group controlId={input.name}>
                        <BsForm.Label>
                          What EV would you like to reserve?
                        </BsForm.Label>
                        <BsForm.Select
                          {...input}
                          isInvalid={meta.touched && meta.invalid}
                        >
                          <option value="">Select one</option>
                          {programsList.map(({id, description}) => (
                            <option key={id} value={id}>
                              {description.replace(" Program", "")}
                            </option>
                          ))}
                        </BsForm.Select>
                        {meta.touched && meta.error && (
                          <BsForm.Control.Feedback type="invalid">
                            {meta.error}
                          </BsForm.Control.Feedback>
                        )}
                      </BsForm.Group>
                    )}
                  </Field>
                  {showProgramDisclosure(values.programId) && (
                    <>
                      <hr />
                      <div className={styles.vehicleUnavailable}>
                        Enter information below. Autonomy will contact you about
                        the availability of your vehicle and your desired timing
                        for delivery.
                      </div>
                    </>
                  )}
                </Col>
              )}
              <Col xs={6}>
                <Field name="firstName" validate={required}>
                  {(props) => <TextField {...props} label="First Name" />}
                </Field>
              </Col>
              <Col xs={6}>
                <Field name="lastName" validate={required}>
                  {(props) => <TextField {...props} label="Last Name" />}
                </Field>
              </Col>
              <Col xs={6}>
                <Field
                  name="mobileNumber"
                  format={formatPhoneNumber}
                  parse={parsePhoneNumber}
                  validate={composeValidators(required, validateMobilePhone)}
                >
                  {(props) => (
                    <TextField
                      {...props}
                      type="tel"
                      autoComplete="tel"
                      label="Mobile #"
                    />
                  )}
                </Field>
              </Col>
              <Col xs={6}>
                <Field
                  name="email"
                  validate={composeValidators(required, validateEmail)}
                >
                  {(props) => (
                    <TextField {...props} autoComplete="email" label="Email" />
                  )}
                </Field>
              </Col>
              <Col xs={6}>
                <Field
                  name="zip"
                  validate={composeValidators(required, validatePostalCode)}
                >
                  {(props) => (
                    <TextField
                      {...props}
                      autoComplete="billing postal-code"
                      label="Zipcode"
                    />
                  )}
                </Field>
              </Col>
            </Row>
            <hr className={styles.divider} />
            <div className={styles.disclosure}>
              {`By tapping "Submit," you agree to our `}{" "}
              <Link href="/tos" legacyBehavior={true}>
                <a>Terms of Service</a>
              </Link>
              ,{" "}
              <Link href="/privacy" legacyBehavior={true}>
                <a>Privacy Policy</a>
              </Link>
              , and{" "}
              <Link href="/esign" legacyBehavior={true}>
                <a>ESIGN Policy</a>
              </Link>
              .{" "}
              {`You also agree to be contacted about
                Autonomy products, including through autodialed calls or texts.
                This is not a condition of purchase. Eligibility check is
                required prior to subscription purchase. See our Privacy Policy
                for how we handle your data.`}
            </div>
            <div>
              <Button
                className={classNames("w-100", styles.submitButton)}
                disabled={submitting}
                type="submit"
                variant="primary"
              >
                {submitting && (
                  <Spinner
                    className="me-2"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                Submit
              </Button>
              {submitError && (
                <div className={styles.submitError}>
                  {`It looks like you already have a reservation in process, please contact us for help at `}
                  <a href="tel:18002886666">1 (800) 288-6666.</a>
                </div>
              )}
            </div>
          </form>
        )}
      />
    </div>
  );
}
